<template>
  <div class="user">
    <i class="el-icon-s-custom personIcon"></i>
    <el-link v-if="!is_login"
             :href="login_url">{{$t('topHead.login_label')}}</el-link>
    <span class="headText"
          v-if="is_login">{{user.nick ? user.nick : user.username}}</span>
    <el-dropdown style="margin-left:12px"
                 v-if="is_login"
                 @command="handleCommand">
      <i class="el-icon-arrow-down headText"
         style="margin-right: 15px"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="loginOut">
          <el-link :href="logout_url">{{$t('topHead.login_out')}}</el-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               :visible="centerDialogVisible"
               :modal-append-to-body="false"
               width="288px"
               custom-class="phoneDialog"
               center>
      <!-- <el-radio-group v-model="radio"
                      v-if="showMail"
                      style="margin-bottom: 10px;display: flex;justify-content: center"
                      size="small">
        <el-radio-button label="phone">{{$t('setting.phone').indexOf('setting') == -1 ? $t('setting.phone') : '手机'}}</el-radio-button>
        <el-radio-button label="mail">{{$t('setting.mail').indexOf('setting') == -1 ? $t('setting.mail') : '邮箱'}}</el-radio-button>
      </el-radio-group> -->
      <template v-if="radio == 'phone'">
        <div style="text-align:center;">{{$t('userInfo.enter_phone_title').indexOf('userInfo') == -1 ? $t('userInfo.enter_phone_title') : '手机号验证'}}</div>
        <div class="phoneBox">
          <div class="enterPhone">
            <el-input :placeholder="$t('userInfo.enter_phone_placeholder').indexOf('userInfo') == -1 ? $t('userInfo.enter_phone_placeholder') : '请输入手机号'"
                      v-model="setting_param.phone"
                      size="mini"
                      ref="phoneEnter" />
          </div>
          <!-- <div class="selectPhone">
            <el-select v-model="setting_param.phone_id"
                       :placeholder="$t('userInfo.select_placeholder').indexOf('userInfo') == -1 ? $t('userInfo.select_placeholder') : '请选择'"
                       size="mini"
                       ref="phoneSelect">
              <el-option v-for="item in phoneOptions"
                         :key="item.phone_id"
                         :label="item.phone"
                         :value="item.phone_id">
              </el-option>
            </el-select>
          </div> -->
          <!-- <div class="searchPhone">
            <el-input class="search_input"
                      size="mini"
                      type="text"
                      v-model="searchText"
                      @input="focusCustomer()"
                      :placeholder="$t('userInfo.search_phone').indexOf('userInfo') == -1 ? $t('userInfo.search_phone') : '搜索手机号'"></el-input>
          </div> -->
        </div>
        <div class="codeBox">
          <div>
            <el-input class="code_input"
                      size="mini"
                      type="text"
                      v-model="setting_param.code"
                      :placeholder="$t('userInfo.enter_sms').indexOf('userInfo') == -1 ? $t('userInfo.enter_sms') : '请输入验证码'"></el-input>
          </div>
          <el-button type="text"
                     :disabled="codeDisabled"
                     size="mini"
                     @click="getSmsCode">{{codeMsg}}</el-button>

        </div>
      </template>
      <template v-else-if="radio == 'mail'">
        <div style="text-align:center;">{{$t('userInfo.enter_email_title').indexOf('userInfo') == -1 ? $t('userInfo.enter_email_title') : '邮箱验证'}}</div>
        <div class="phoneBox">
          <div class="enterPhone">
            <el-input :placeholder="$t('userInfo.enter_email_placeholder').indexOf('userInfo') == -1 ? $t('userInfo.enter_email_placeholder') : '请输入邮箱'"
                      v-model="setting_param.mail"
                      size="mini"
                      ref="phoneEnter" />
          </div>
          <!-- <div class="selectPhone">
            <el-select v-model="setting_param.mail_id"
                       :placeholder="$t('userInfo.select_placeholder').indexOf('userInfo') == -1 ? $t('userInfo.select_placeholder') : '请输入邮箱'"
                       size="mini"
                       ref="phoneSelect">
              <el-option v-for="item in mailOptions"
                         :key="item.mail_id"
                         :label="item.mail"
                         :value="item.mail_id">
              </el-option>
            </el-select>
          </div> -->
          <!-- <div class="searchPhone">
            <el-input class="search_input"
                      size="mini"
                      type="text"
                      v-model="searchText"
                      @input="focusCustomer()"
                      :placeholder="$t('userInfo.search_mail').indexOf('userInfo') == -1 ? $t('userInfo.search_mail') : '搜索邮箱'"></el-input>
          </div> -->
        </div>
        <div class="codeBox">
          <div>
            <el-input class="code_input"
                      size="mini"
                      type="text"
                      v-model="setting_param.mail_code"
                      :placeholder="$t('userInfo.enter_sms').indexOf('userInfo') == -1 ? $t('userInfo.enter_sms') : '请输入验证码'"></el-input>
          </div>
          <el-button type="text"
                     :disabled="codeDisabled"
                     size="mini"
                     @click="getSmsCode">{{codeMsg}}</el-button>

        </div>
      </template>
      <span slot="footer"
            class="dialog-footer">
        <el-button size="mini"
                   @click="goLogin"
                   style="margin-right:8px;">{{$t('userInfo.cancel').indexOf('userInfo') == -1 ? $t('userInfo.cancel') : '取消'}}</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="verification">{{$t('userInfo.verify').indexOf('userInfo') == -1 ? $t('userInfo.verify') : '验证'}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="true"
               :close-on-press-escape="true"
               :show-close="false"
               :visible="codeDialogVisible"
               @close="closeCodeDialog"
               :modal-append-to-body="false"
               width="248px"
               custom-class="phoneDialog"
               center>
      <template>
        <div v-if="radio == 'phone'"
             style="text-align:center; margin-top: 50px;">手机号码未完成注册</div>
        <div v-if="radio == 'mail'"
             style="text-align:center; margin-top: 50px;">邮箱未完成注册</div>
        <div style="text-align:center;">请联系数据顾问</div>
        <i class="el-icon-close"
           @click="closeCodeDialog" />
      </template>
      <span slot="footer"
            class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserInfo,
  smsSendLogin,
  smsVerifyLogin,
  mailSendLogin,
  mailVerifyLogin,
} from '@/api/user'
import { getPhone, getMail } from '@/api/public'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import userflow from 'userflow.js'
import mixpanel from 'mixpanel-browser'
import { getObjFromUrl } from '@/utils'
mixpanel.init('de23f732c4e29f1419141f777bcd616f', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
})
//免费版不使用userflow/mixpanel
if (window.location.href.indexOf('radar') < 0) {
  if (
    window.location.href.indexOf('test.mktindex.com') != -1 ||
    window.location.href.indexOf('dev.mktindex.com') != -1
  ) {
    //正式环境为ct_e6r4qjncnze7nktxnkma37ozpi，测试环境为ct_syo72uduzvekjcah5kd5mj5hue
    userflow.init('ct_syo72uduzvekjcah5kd5mj5hue')
  } else {
    userflow.init('ct_e6r4qjncnze7nktxnkma37ozpi')
  }
}
export default {
  data() {
    return {
      user: {
        username: '',
        usertype: 'logged_out',
        deadline: '',
        nick: '',
        version: '',
      },
      is_login: false,
      login_url:
        'https://mjlogintest.mktindex.com/please_login?service=' +
        window.location.origin +
        '/login/&redirect_url=' +
        encodeURIComponent(window.location.href),
      logout_url:
        '/mj_logout?redirect_url=' + encodeURIComponent(window.location.href),
      setting_param: {
        phone_id: '',
        phone: '',
        code: '',
        mail_id: '',
        mail: '',
        mail_code: '',
      },
      options: [],
      phoneOptions: [],
      newArr: [],
      searchText: '',
      centerDialogVisible: false,
      codeDisabled: false,
      countdown: 60,
      codeMsg:
        this.$t('userInfo.get_sms').indexOf('userInfo') == -1
          ? this.$t('userInfo.get_sms')
          : '获取验证码',
      timer: null,

      showMail: false,
      radio: 'phone',
      // mailOptions: [],
      moptions: [],
      codeDialogVisible: false,
    }
  },
  methods: {
    goLogin() {
      window.location.href = this.logout_url
    },
    handleCommand(command) {
      if (command == 'loginOut') {
        sessionStorage.removeItem('hasVerifySetting')
      }
    },
    // focusCustomer() {
    //   var reg = this.searchText.trim()
    //   if (this.radio == 'phone') {
    //     if (reg.length > 0) {
    //       this.newArr = this.options.filter((item) => {
    //         var numberfliter = item.phone
    //           .toUpperCase()
    //           .indexOf(reg.toUpperCase())
    //         if (numberfliter != -1) {
    //           return item
    //         }
    //       })
    //       this.phoneOptions = this.newArr
    //       if (this.newArr.length == 0) {
    //         this.$set(this.setting_param, 'phone_id', '')
    //       } else {
    //         this.$set(
    //           this.setting_param,
    //           'phone_id',
    //           this.phoneOptions[0].phone_id
    //         )
    //       }
    //       // this.$refs.phoneSelect.toggleMenu()
    //     } else {
    //       this.phoneOptions = this.options
    //     }
    //   }
    //   if (this.radio == 'mail') {
    //     if (reg.length > 0) {
    //       this.newArr = this.moptions.filter((item) => {
    //         var numberfliter = item.mail
    //           .toUpperCase()
    //           .indexOf(reg.toUpperCase())
    //         if (numberfliter != -1) {
    //           return item
    //         }
    //       })
    //       this.mailOptions = this.newArr
    //       if (this.newArr.length == 0) {
    //         this.$set(this.setting_param, 'mail_id', '')
    //       } else {
    //         this.$set(
    //           this.setting_param,
    //           'mail_id',
    //           this.mailOptions[0].mail_id
    //         )
    //       }
    //       // this.$refs.phoneSelect.toggleMenu()
    //     } else {
    //       this.mailOptions = this.moptions
    //     }
    //   }
    // },
    getSmsCode() {
      if (!this.codeDisabled) {
        this.codeDisabled = true
        let param = {}
        let login
        if (this.radio == 'phone') {
          param = { phone: this.setting_param.phone }
          login = smsSendLogin
        } else if (this.radio == 'mail') {
          param = { mail: this.setting_param.mail }
          login = mailSendLogin
        }

        login(param)
          .then((res) => {
            // 验证码60秒倒计时
            if (!this.timer) {
              this.getValidStr()
              this.timer = setInterval(this.getValidStr, 1000)
            }
            if (res.status == 'ok') {
            } else {
              this.$message.warning(res.error_msg)
            }
          })
          .catch((e) => {
            if (e.data.status == 'error') {
              if (e.data.error_code == '-497') {
                this.codeDialogVisible = true
              } else {
                this.$message.warning(e.data.error_msg)
              }
            }
            this.codeDisabled = false
          })
          .finally(() => {})
      }
    },
    closeCodeDialog() {
      this.codeDialogVisible = false
    },
    getValidStr() {
      if (this.countdown > 0 && this.countdown <= 60) {
        this.countdown--
        if (this.countdown !== 0) {
          this.codeMsg = this.countdown + 's'
        } else {
          clearInterval(this.timer)
          this.codeMsg = '获取验证码'
          this.countdown = 60
          this.timer = null
          this.codeDisabled = false
        }
      }
    },
    verification() {
      let self = this
      let params = {}
      let verify
      if (this.radio == 'phone') {
        if (this.setting_param.phone == '') {
          this.$message.warning('请选择手机号')
          return
        }
        let active = _.find(this.options, function (o) {
          return o.phone == self.setting_param.phone
        })
        if (active && active.is_login_verify) {
          if (this.setting_param.code == '') {
            this.$message.warning('请输入短信验证码')
            return
          }
        }
        params = {
          phone: this.setting_param.phone,
          code: this.setting_param.code,
        }
        verify = smsVerifyLogin
      } else if (this.radio == 'mail') {
        if (this.setting_param.mail == '') {
          this.$message.warning('请选择邮箱')
          return
        }
        let active = _.find(this.moptions, function (o) {
          return o.mail == self.setting_param.mail
        })
        if (active) {
          if (this.setting_param.mail_code == '') {
            this.$message.warning('请输入邮箱验证码')
            return
          }
        }
        params = {
          mail: this.setting_param.mail,
          code: this.setting_param.mail_code,
        }
        verify = mailVerifyLogin
      }

      verify(params)
        .then((res) => {
          if (res.status == 'ok') {
            location.reload()
          } else {
          }
        })
        .catch((e) => {
          this.$message.error('验证码错误')
        })
    },
    trackTabChange() {
      document.addEventListener('click', (event) => {
        const target = event.target
        const tabElement = target.closest('[role="tab"]')
        const searchElement = target.closest('.form-search')
        const timeElement = target.closest('.time-range-btn')
        const selectSearchElement = target.closest('.select-search-btn')
        if (tabElement !== null) {
          let tabName = tabElement.innerText
          let tabId = tabElement.getAttribute('id')
          let query = getObjFromUrl(window.location.hash)
          const params = {
            tab_name: tabName,
            url_id: query.url_id,
            start: query.start,
            end: query.end,
            tab_id: tabId,
          }
          mixpanel.track('Tab Change', params)
        } else if (searchElement !== null) {
          const form = document.forms[0]
          const ztreeElement = form.querySelector('.ztree')
          const ztreeId = ztreeElement.getAttribute('id')
          const ztreeObj = $.fn.zTree.getZTreeObj(ztreeId)
          let ignoreIds = []
          const checkNodes = []
          ztreeObj.getCheckedNodes().forEach((item) => {
            // 该节点被全选且父节点未被选中,则子节点id不传入
            if (
              item.check_Child_State == 2 &&
              ignoreIds.indexOf(item.id) == -1
            ) {
              checkNodes.push(item)
              ignoreIds.push(...this.getChdNodesIds(item))
            } // 父节点不是全选,子节点没有children
            else if (
              item.check_Child_State == -1 &&
              ignoreIds.indexOf(item.id) == -1
            ) {
              checkNodes.push(item)
            }
          })
          const map = {}
          map['平台'] = []
          let attrArr = []
          // 遍历表单元素
          for (let i = 0; i < form.elements.length; i++) {
            let element = form.elements[i]
            if (element.type == 'checkbox' && element.checked) {
              const labelElement = this.findNearestLabel(element)
              if (labelElement.innerText.includes('平台')) {
                map['平台'].push(element.value)
              }
            }
            if (element.parentElement.classList.contains('el-input--mini')) {
              const labelElement = this.findNearestLabel(element)
              if (labelElement.innerText.includes('类目')) {
                continue
              }
              if (
                map[labelElement.innerText] &&
                !labelElement.innerText.includes('属性')
              ) {
                map[labelElement.innerText] = [
                  map[labelElement.innerText],
                  element.value,
                ]
              } else if (
                map[labelElement.innerText] &&
                labelElement.innerText.includes('属性')
              ) {
                attrArr.push(element.value)
              } else if (element.value) {
                map[labelElement.innerText] = element.value
              }
            }
          }
          map.cats = checkNodes.map((item) => {
            return item.label
          })
          map['属性'] = {}
          for (let index = 0; index < attrArr.length; index = index + 2) {
            const element = attrArr[index]
            if ((index + 1) % 2 != 0) {
              map['属性'][element] = attrArr[index + 1]
            }
          }
          if (map.cats.length == 0) {
            return
          }
          console.log(map)
          mixpanel.track('Search Change', map)
        } else if (timeElement !== null) {
          let query = getObjFromUrl(window.location.hash)
          let start = query.start,
            end = query.end,
            tsType = sessionStorage.getItem('tsType')
              ? sessionStorage.getItem('tsType')
              : 'month'
          const params = { monthType: tsType, start, end }
          mixpanel.track('Time Search Change', params)
        } else if (selectSearchElement !== null) {
          let query = getObjFromUrl(window.location.hash)
          let selectElement = document.querySelector('.item-select').children[0].children[0].value
          if (selectElement == '') {
            selectElement = '商品'
          }
          const params = {
            monthType: sessionStorage.getItem('tsType')
              ? sessionStorage.getItem('tsType')
              : 'month',
            start: query.start,
            end: query.end,
            fx_url_id: this.$store.state.user.fxUrlId || '',
            brand_id: this.$store.state.user.brandId || 'all',
            item_id: this.$store.state.user.itemId || '',
            sku_id: this.$store.state.user.skuId || '',
            shop_id: this.$store.state.user.shopId || '',
            item_analyse: this.$store.state.user.itemAnalyse || '',
            price_max: this.$store.state.user.priceMax || '',
            price_min: this.$store.state.user.priceMin || '',
            content_word: this.$store.state.user.contentWord ? decodeURIComponent(this.$store.state.user.contentWord) : '',
            title_word: this.$store.state.user.titleWord ? decodeURIComponent(this.$store.state.user.titleWord) : '',
            title_typ: this.$store.state.user.titleTyp ? decodeURIComponent(this.$store.state.user.titleTyp) : '',
          }
          if (selectElement == '商品') {
            let title_word = document.querySelector('.search-input').children[0].value
            if (title_word) {
              params.title_word = title_word
            }
          } else if (selectElement == '评论') {
            let content_word = document.querySelector('.search-input').children[0].value
            if (content_word) {
              params.content_word = content_word
            }
          } else if (selectElement == '价格') {
            let price_min = document.querySelector('.price-min-input').children[0].value
            let price_max = document.querySelector('.price-max-input').children[0].value
            if (price_min) {
              params.price_min = price_min
            }
            if (price_max) {
              params.price_max = price_max
            }
          }
          mixpanel.track('Search Change', params)
        } else {
          return
        }
      })
    },
    getChdNodesIds(nodes) {
      const ids = []
      if (nodes.children && nodes.children.length > 0) {
        nodes.children.forEach((item) => {
          ids.push(item.id)
          if (item.children && item.children.length > 0) {
            ids.push(...this.getChdNodesIds(item))
          }
        })
      }
      return ids
    },
    findNearestLabel(element) {
      var parent = element.parentNode
      let label = null
      while (parent) {
        label = parent.querySelector('.el-form-item__label')

        if (label) {
          return label
          break
        }

        parent = parent.parentNode
      }
    },
    // 用户语言设置
    localLanguage() {
      let language = localStorage.getItem('language')
      // 用户未设置语言
      if (!language) {
        let lang = 'zh'
        if (navigator.language.startsWith('en')) {
          lang = 'en'
          localStorage.setItem('language', lang)
        } else {
          localStorage.setItem('language', lang)
        }
        this.$emit('on-changeLanguage', lang)
      }
    },
  },
  computed: {},
  async created() {
    let that = this
    const browserFinger = localStorage.getItem('browserFinger')
    this.localLanguage()
    getUserInfo()
      .then((res) => {
        if (res.status == 'ok') {
          if (res.result.usertype != 'logged_out') {
            that.user.username = res.result.username
            that.user.deadline = res.result.deadline
            that.user.nick = res.result.nick
            that.user.usertype = res.result.usertype
            that.is_login = true
            var d = new Date()
            //add userflow event
            let phone = res.result.phone ? res.result.phone : '****'
            let visitorId = res.result.username + '_' + phone + '_'
            let origin =  window.location.origin
            if(origin.indexOf('lining.com') > 0) {
              this.$sensors.registerPage({
                cem_userName: res.result.username,
                cem_userId: res.result.username_t
              })
              this.$sensors.setProfile({
                cem_userName: res.result.username,
                cem_userId: res.result.username_t
              })
	          }
            if (browserFinger) {
              //用户名+手机号+浏览器id后五位
              visitorId += browserFinger.slice(-5)
            }
            let infoObj = {
              due_date: res.result.due_date ? res.result.due_date : '', // 到期时间
              browser_fingerprint: browserFinger ? browserFinger : '', // 浏览器id
              pm_id: res.result.pm_id ? res.result.pm_id : '', // 线索工单（从super后台开权限时添加的数据来）
              account: res.result.username ? res.result.username : '', // 魔镜账号，即username
              account_type: res.result.account_type ? res.result.account_type : '', // 账号类型，试用？正式付费？CMI试用？
              reg_sales: res.result.reg_sales ? res.result.reg_sales : '', // 注册销售（从super后台开权限时添加的数据来）
              sub_account: res.result.sub_account ? res.result.sub_account : '', // 子账号，当前登录的手机号
              name: visitorId,
            }
            //only collocate chrome browser now.
            infoObj = Object.assign(infoObj, res.result.attribute)
            //免费版不使用userflow/mixpanel
            if (
              window.navigator.userAgent.indexOf('Chrome') > 0 &&
              browserFinger &&
              window.location.href.indexOf('radar') < 0 &&
              res.result.username != 'screenshot'
            ) {
              if (
                window.location.href.indexOf('test.mktindex.com') != -1 ||
                window.location.href.indexOf('dev.mktindex.com') != -1
              ) {
                //if (res.result.username.endsWith('@moojing.com')) {
                userflow.identify(visitorId, infoObj)
                mixpanel.identify(visitorId)
                mixpanel.people.set(infoObj)
                console.log('send userinfo to userflow mixpanel')
                //}
              } else if (window.location.href.indexOf('mktindex.com') != -1) {
                userflow.identify(visitorId, infoObj)
                mixpanel.identify(visitorId)
                mixpanel.people.set(infoObj)
              } else {
                console.log('skip mixpanel userinfo', window.location.href)
              }
              window.mixpanel = mixpanel
            }
            this.trackTabChange()
            that.$emit('userLogin', res.result)
          } else {
            //for oem proxy_pass goto oem login url
            if(res.result.not_allow){
              this.$message.error('您没有权限访问此系统，请联系管理员!');
            }
            var origin = window.location.origin
            if (origin.indexOf('mktindex') < 0) {
              console.log('oem login', window.location.origin)
              if (origin.indexOf('cemtest.lining.com') > -1 ) {
                var d = new Date().getTime();
                window.location.href = 'https://ssouat.lining.com/esc-sso/oauth2.0/authorize?client_id=pBKuYWBH0j&redirect_uri=https://cemtest.lining.com/api/sso_callback&response_type=code&target_uri=https://cemtest.lining.com/dashboard&oauth_timestamp='+d;  
              }else if (origin.indexOf('lining.com') != -1) {
                  var d = new Date().getTime();
                  window.location.href = 'https://sso.lining.com/esc-sso/oauth2.0/authorize?client_id=pBKuYWBH0j&redirect_uri='+origin+'/api/sso_callback&response_type=code&target_uri='+origin+'/dashboard&oauth_timestamp='+d;
              } else {
                window.location.href = window.location.origin + '/login'
              }
            } else {
              console.log('goto login', that.login_url)
              window.location.href = that.login_url
            }
          }
        }
      })
      .catch((e) => {
        console.log(e)
        if (e.data.status == 'error' && e.data.error_code == -499) {
          getPhone()
            .then((res) => {
              if (res.status == 'ok') {
                // if (res.result.length > 0) {
                //   this.setting_param.phone_id = res.result[0].phone_id
                //   this.setting_param.phone = res.result[0].phone
                // }
                this.options = res.result
                this.phoneOptions = res.result
              } else {
                console.log(res)
              }
              this.centerDialogVisible = true
            })
            .catch((e) => {
              console.log(e)
            })
          getMail()
            .then((res) => {
              const result = res.result
              const mail = result.mail
              if (mail && mail.length > 0) {
                // this.setting_param.mail_id = mail[0].mail_id
                // this.setting_param.mail = mail[0].mail
                this.showMail = true
                // this.mailOptions = mail
                this.moptions = mail
                this.radio = 'mail'
              } else {
                this.showMail = false
                this.radio = 'phone'
              }
              if (this.phoneOptions.length == 0) {
                this.centerDialogVisible = true
              }
            })
            .catch((e) => {})
        } else if (e.data.status == 'error') {
          /**
           * e = {
           *  data: {
           *    error_msg: {
           *      msg: "",
           *      msg_en: ""
           *    }
           *  }
           * }
           */
          this.$message.error(e.data.error_msg.msg)
        }
      })
      .finally(() => {})
  },
}
</script>
<style scoped lang='less'>
.user {
  /deep/ .phoneDialog {
    .phoneBox {
      display: flex;
      padding-left: 24px;
      margin: 16px 0 12px;
      // .selectPhone {
      //   margin-right: 12px;
      // }
      .enterPhone {
        width: inherit;
        .el-input__inner {
          width: 240px;
          height: 28px;
          border: 1px solid #ccc;
        }
      }
      // .search_input {
      //   .el-input__inner {
      //     width: 100px;
      //   }
      // }
    }
    .codeBox {
      display: flex;
      padding: 0 24px;
      .code_input {
        margin-right: 12px;
        .el-input__inner {
          width: 128px;
          height: 28px;
          border: 1px solid #ccc;
        }
      }
    }
    .el-dialog__header {
      padding: 0 !important;
    }
    .el-dialog__body {
      padding: 24px 0 55px !important;
    }
    .el-dialog__footer {
      padding-bottom: 24px;
    }
    .el-icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
</style>
